import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'normalize.css/normalize.css';
import { CookiesProvider } from 'react-cookie';
import AccountPage from './components/AccountPage';
import Login from './components/Login';
import './assets/sass/main.scss';

const jsx = (
  <CookiesProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/form" component={AccountPage} />
        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  </CookiesProvider>
);

ReactDOM.render(jsx, document.getElementById('app'));

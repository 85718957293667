import React, { Component } from 'react';
import { Button } from '@abtasty/turfu-common-ui';
import AccountPack from '../AccountPack';
import Translations from '../../services/Translations';
import SaleforceAccount from '../SaleforceAccount/SaleforceAccount';
import AccountName from '../AccountName/AccountName';

class FormStepOne extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }
  handleSubmitForm = e => {
    e.preventDefault();
    if (this.checkValidForm()) {
      return this.props.handleSubmit('One');
    }
    return this.displayError();
  };

  checkValidForm = () => {
    if (this.props.salesforceAccount && this.props.accountName && this.props.selectedPack) {
      if (this.props.selectedPack === 'quota_custom' && (!this.props.expDatePack || !this.props.uvPack)) return false;
      return true;
    }
    return false;
  };

  displayError = () => {
    this.errorTimer = setTimeout(() => {
      this.setState({ error: '' });
    }, 4000);

    this.setState({ error: 'Please fill the form properly' }, () => this.errorTimer);
  };

  componentWillUnmount() {
    clearTimeout(this.errorTimer);
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmitForm(e)}>
        <SaleforceAccount
          salesforceDatas={this.props.salesforceDatas}
          handleSalesforceAccountChange={this.props.handleSalesforceAccountChange}
          handleSalesforceAccountInputChange={this.props.handleSalesforceAccountInputChange}
          salesforceAccount={this.props.salesforceAccount}
        />
        <AccountName accountName={this.props.accountName} handleAccountName={this.props.handleAccountName} />
        <AccountPack
          handlePackChange={this.props.handlePackChange}
          handleUVPack={this.props.handleUVPack}
          handleExpDatePack={this.props.handleExpDatePack}
          selectedPack={this.props.selectedPack}
          expDatePack={this.props.expDatePack}
        />
        <Button className="ac__form__submit-btn" size="medium" label={Translations.getTranslation('ac-save')} />
        <p className="ac__form__error">{this.state.error ? this.state.error : null}</p>
      </form>
    );
  }
}

export default FormStepOne;

export default {
  packs: [
    {
      label: 'Premium',
      value: 'premium',
    },
    {
      label: 'Demo premium',
      value: 'demo_premium',
    },
    {
      label: 'Quota custom',
      value: 'quota_custom',
    },
  ],
  daily_quota: [
    {
      label: '500',
      value: 500,
    },
    {
      label: '1000',
      value: 1000,
    },
    {
      label: '2000',
      value: 2000,
    },
    {
      label: '5000',
      value: 5000,
    },
    {
      label: '10000',
      value: 10000,
    },
    {
      label: '15000',
      value: 15000,
    },
    {
      label: '30000',
      value: 30000,
    },
  ],
  account_rights: [
    {
      label: 'Admin',
      value: 'admin',
    },
    {
      label: 'User',
      value: 'user',
    },
    {
      label: 'Creator',
      value: 'creator',
    },
    {
      label: 'Viewer',
      value: 'viewer',
    },
  ],
};

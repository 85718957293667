import React from 'react';
import { Select } from '@abtasty/turfu-common-ui';
import Translations from '../../services/Translations';

const SaleforceAccount = props => (
  <div className="ac__form__group">
    <label>1 - {Translations.getTranslation('ac-saleforceAccount')}</label>
    <Select
      className="ac__form__select"
      options={props.salesforceDatas}
      value={props.salesforceAccount}
      onChange={props.handleSalesforceAccountChange}
      onInputChange={props.handleSalesforceAccountInputChange}
    />
  </div>
);

export default SaleforceAccount;

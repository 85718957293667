import React from 'react';
import { Select } from '@abtasty/turfu-common-ui';
import Translations from '../../services/Translations';

const AccountManager = props => (
  <div>
    <label>4 - {Translations.getTranslation('ac-accountManager')}</label>
    <Select
      className="ac__form__select"
      onChange={props.handleManagerAccount}
      value={props.managerAccount}
      options={props.managerAccountDatas}
    />
  </div>
);

export default AccountManager;

import React from 'react';
import Translations from '../../services/Translations';
import AccountPremiumOpt from '../AccountPremiumOpt';

const AccountPremiumOpts = props => (
  <div className="ac__form__accountOptions">
    <label htmlFor="ac__form__premium-opt">5 - {Translations.getTranslation('ac-premium-opt')}</label>
    <div className="ac__form__accountOptions__wrapper">
      {Object.keys(props.premiumsOptionsAvailable).map(premiumOption => (
        <AccountPremiumOpt
          handlePremiumOptionChange={props.handlePremiumOptionChange}
          key={premiumOption}
          label={premiumOption}
          option={props.premiumsOptionsAvailable[premiumOption]}
          dailyQuota={props.dailyQuota}
          handlePremiumOptionSRDailyQuota={props.handlePremiumOptionSRDailyQuota}
        />
      ))}
    </div>
  </div>
);

export default AccountPremiumOpts;

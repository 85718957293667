import React, { Component } from 'react';
import { Button } from '@abtasty/turfu-common-ui';
import ListUsers from '../ListUsers';
import Translations from '../../services/Translations';
import FormUser from '../FormUser/FormUser';

class FormStepThree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  handleSubmitForm = e => {
    e.preventDefault();
    if (this.checkErrors()) {
      return this.props.handleSubmit('Three');
    }
    return false;
  };

  checkErrors = () => {
    if (!this.props.users.length) return false;
    return true;
  };
  render() {
    return (
      <form onSubmit={e => this.handleSubmitForm(e)}>
        <FormUser handleAddUser={this.props.handleAddUser} />
        <ListUsers users={this.props.users} handleDeleteUser={this.props.handleDeleteUser} />
        {this.props.users.length ? (
          <Button className="ac__form__submit-btn" size="medium" label={Translations.getTranslation('ac-save')} />
        ) : null}
      </form>
    );
  }
}

export default FormStepThree;

const transformManagersAccount = datas =>
  datas.map(data => ({
    label: `${data.lastname} ${data.firstname}`,
    value: data.email,
  }));

const transformSalesforceDatas = datas =>
  datas.map(data => ({
    label: data.Name,
    value: data.Id,
  }));

const extractAccountId = location => location.split(new RegExp('/api/core/accounts/'))[1];

const debounce = (func, timeout = 500) => {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, timeout);
  };
};

export default { transformManagersAccount, transformSalesforceDatas, extractAccountId, debounce };

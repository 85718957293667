import React, { Component } from 'react';
import { Input, Select, Button } from '@abtasty/turfu-common-ui';
import Translations from '../../services/Translations';
import staticData from '../../datas/static_account_data';

export default class FormUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      user: {
        email: '',
        right: '',
      },
    };
  }

  handleUserEmail = e => {
    const user = {
      ...this.state.user,
      email: e.target.value,
    };
    this.setState({ user });
  };

  handleUserRight = target => {
    const user = {
      ...this.state.user,
      right: target ? target.value : '',
    };
    this.setState({ user });
  };

  handleAddUser = () => {
    const error = this.props.handleAddUser(this.state.user);

    if (error) this.displayError(error);
    else {
      this.setState({
        user: {
          email: '',
          right: '',
        },
      });
    }
  };

  displayError = error => {
    this.errorTimer = setTimeout(() => {
      this.setState({ error: '' });
    }, 4000);

    this.setState({ error }, () => this.errorTimer);
  };

  componentWillUnmount() {
    clearTimeout(this.errorTimer);
  }
  render() {
    return (
      <div className="ac__form__group">
        <label htmlFor="ac__form__add-account">{Translations.getTranslation('ac-add_user')}</label>
        <div className="ac__form__add-account">
          <div className="ac__form__group">
            <Input
              className="ac__form__user-name ac__form__input ac__form__input--full-width"
              type="email"
              value={this.state.user.email}
              onChange={this.handleUserEmail}
            />
            <Select
              className="ac__form__user-right"
              value={this.state.user.right}
              options={staticData.account_rights}
              onChange={this.handleUserRight}
            />
            <Button
              className="ac__form__add-account__button"
              type="button"
              size="small"
              onClick={this.handleAddUser}
              label={Translations.getTranslation('ac-add_btn')}
            />
          </div>
        </div>
        <p className="ac__form__error">{this.state.error}</p>
      </div>
    );
  }
}

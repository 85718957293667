import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Input, Button } from '@abtasty/turfu-common-ui';
import API from '../../services/API';
import Header from '../Header';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  handleInputChange = (value, type) => this.setState({ [type]: value });

  handleSubmitLogin = e => {
    const { cookies, history } = this.props;
    e.preventDefault();
    API.postLogin(this.state).then(response => {
      window.localStorage.setItem('userEmail', this.state.username);
      cookies.set('APP2WT', response.data.access_token, { path: '/' });
      cookies.set('APP2RT', response.data.refresh_token, { path: '/' });
      cookies.set('APP2RT2', response.data.refresh_token, { path: '/' });
      history.push('/form');
    });
  };

  render() {
    return (
      <div className="ac__container">
        <Header />
        <form>
          <div className="ac__form__group">
            <label>Email</label>
            <Input
              className="ac__form__input"
              required={true}
              onChange={e => this.handleInputChange(e.target.value, 'username')}
            />
          </div>

          <div className="ac__form__group">
            <label>Password</label>
            <Input
              className="ac__form__input"
              required={true}
              onChange={e => this.handleInputChange(e.target.value, 'password')}
              type="password"
            />
          </div>
          <Button className="ac__form__submit-btn" label="Submit" onClick={this.handleSubmitLogin} />
        </form>
      </div>
    );
  }
}

export default withCookies(Login);

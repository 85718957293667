import React from 'react';
import { Button } from '@abtasty/turfu-common-ui';
import Translations from '../../services/Translations';

const ListUsers = props => (
  <div className="ac__form__group">
    {props.users.map((user, idx) => (
      <div key={idx} className="ac__form__account--added">
        <div className="ac__form__group">
          <p className="ac__form__account--added__user">{user.email}</p>
          <p className="ac__form__account--added__user">{user.right}</p>
          <Button
            className="ac__form__account--added__button"
            type="button"
            size="small"
            label={Translations.getTranslation('ac-remove_user')}
            onClick={() => props.handleDeleteUser(idx)}
          />
        </div>
      </div>
    ))}
  </div>
);

export default ListUsers;

import React from 'react';

const Success = () => (
  <div className={'ac__success'}>
    <p className="ac__success__wording">
      <i className="material-icons">check_circle_outline</i>
    </p>
  </div>
);

export default Success;

import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import Header from '../Header';
import API from '../../services/API';
import helpers from '../../utils/helpers';
import Translations from '../../services/Translations';
import AccountForm from '../AccountForm';
import Loader from '../Loader';
import Success from '../Success';

export class AccountPage extends Component {
  constructor(props) {
    super(props);
    const { cookies } = this.props;
    this.state = {
      loading: true,
      success: false,
      step: 1,
      managerAccountDatas: [],
      salesforceDatas: [],
      accountId: '',
    };
    this.token = {
      APP2WT: cookies.get('APP2WT'),
      set: (name, newToken) => {
        this.name = newToken;
      },
    };
    this.headers = {
      Authorization: `Bearer ${this.token.APP2WT}`,
    };
  }
  initData = () => {
    API.getInitDatas(this.headers)
      .then(datas => {
        const translationData = datas[0].data;
        const managerAccountDatas = helpers.transformManagersAccount(datas[1].data._embedded.items);
        const salesforceDatas = helpers.transformSalesforceDatas(datas[2].data);
        // clone translations data in service array.
        Translations.translations = { ...translationData };
        this.setState({
          loading: false,
          salesforceDatas,
          managerAccountDatas,
        });
      })
      .catch(({ response: errorResponseAccounts }) =>
        API.handleTokenRefreshing(errorResponseAccounts, this.token, () => {})
      );
  };
  handleUpdateSalesforceData = helpers.debounce(name => {
    API.getSalesForceAccount(this.headers, name).then(({ data }) => {
      this.setState({ salesforceDatas: helpers.transformSalesforceDatas(data) });
    });
  });

  handleSubmitOne = ({ accountName, selectedPack, salesforceAccount, expDatePack, uvPack }) => {
    this.setState({ loading: true });
    const data = {
      name: accountName,
      pack: selectedPack,
      salesforce_account_id: salesforceAccount.value,
      quota_valid_until: `${expDatePack}T00:00:00`,
      quota: uvPack,
    };
    if (!expDatePack) delete data.quota_valid_until;
    if (!uvPack) delete data.quota;
    API.createABAccount(data, this.headers)
      .then(({ headers }) => {
        const accountId = helpers.extractAccountId(headers.location);
        this.setState({
          accountId,
          loading: false,
          step: 2,
        });
        return accountId;
      })
      .then(accountId => {
        API.postAdditionalInformation(accountId, this.headers, {
          key: 'fast_report_switch_date',
          value: moment().format('YYYY-MM-DD'),
        });
      });
  };

  handleSubmitTwo = ({ premiumOptions, managerAccount, dailyQuota }) => {
    if (premiumOptions.insights_session_recording && !dailyQuota) return;
    this.setState({ loading: true });
    const data = {
      premiumOptions,
      managerAccount,
      daily_quota: dailyQuota,
      accountId: this.state.accountId,
    };
    API.updateRightsAndManager(data, this.headers).then(() => {
      this.setState({ loading: false, step: 3 });
    });
  };

  handleSubmitThree = ({ users }) => {
    this.setState({ loading: true });
    API.postUsers(this.state.accountId, users, this.headers)
      .then(() => {
        this.setState({ loading: false, success: true }, () => {
          setTimeout(() => {
            this.setState({ success: false, step: 1 });
          }, 3000);
        });
      })
      .catch(() => this.setState({ loading: false }));
  };
  componentDidMount() {
    this.initData();
  }
  render() {
    if (this.state.success) {
      return <Success />;
    }
    if (this.state.loading) {
      return <Loader />;
    }
    return (
      <div className="ac__container">
        <Header step={this.state.step} />
        <AccountForm
          managerAccountDatas={this.state.managerAccountDatas}
          salesforceDatas={this.state.salesforceDatas}
          handleUpdateSalesforceData={this.handleUpdateSalesforceData}
          handleSubmitOne={this.handleSubmitOne}
          handleSubmitTwo={this.handleSubmitTwo}
          handleSubmitThree={this.handleSubmitThree}
          step={this.state.step}
        />
      </div>
    );
  }
}

export default withCookies(AccountPage);

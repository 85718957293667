import axios from 'axios';

export default {
  CLIENTID: '1_5550xg1fpu8ss4cc0c4sscw4sswswoc008sckgw8kocg4w0ogo',
  CLIENTSECRET: '370j0g2u1zacowg88oc080goso8kwcgc8kkg8004ckgs8owgko',
  routes: {
    auth: '/oauth/v2/token',
    register: '/api/core/accounts',
    translations: '/api/core/parameters/translations',
    managerAccount: '/api/core/accounts_managers',
    salesforce: '/api/core/salesforce/accounts',
    account: '/api/core/accounts/{account_id}',
    additional_information: '/api/core/accounts/{account_id}/additional_information',
    membership: '/api/core/accounts/{account_id}/membership',
  },
  callAPI(url, headers, method, data) {
    return axios({
      method,
      url: API_APP2 + url,
      headers,
      data,
    });
  },
  /* eslint-disable */
  apiUrlTemplate(route, params) {
    let more = '?';
    Object.keys(params).map((key, index) => {
      if (route.match(new RegExp(`{${key}}`))) {
        route = route.replace(`{${key}}`, params[key]);
      } else {
        more += `&${key}=${index}`;
      }
    });
    more = more.replace('?&', '?');
    if (more !== '?') route += more;
    return route;
  },
  /* eslint-enable */
  postLogin(params) {
    const data = {
      client_id: this.CLIENTID,
      client_secret: this.CLIENTSECRET,
      grant_type: 'password',
      ...params,
    };

    return this.callAPI(
      `${this.routes.auth}`,
      {
        Authorization: 'Bearer',
        'Content-Type': 'application/json',
      },
      'POST',
      data
    );
  },
  postAdditionalInformation(accountId, headers, data) {
    return this.callAPI(
      `${this.apiUrlTemplate(this.routes.additional_information, {
        account_id: accountId,
      })}`,
      headers,
      'POST',
      data
    );
  },
  createABAccount(data, headers) {
    return this.callAPI(`${this.routes.register}`, headers, 'POST', {
      account_type: 'web',
      ...data,
    });
  },
  updateRightsAndManager(datas, headers) {
    const promises = [];
    promises.push(
      this.callAPI(
        `${this.apiUrlTemplate(this.routes.account, {
          account_id: datas.accountId,
        })}`,
        headers,
        'PUT',
        {
          right: { ...datas.premiumOptions },
        }
      )
    );
    if (datas.managerAccount) {
      promises.push(
        this.postAdditionalInformation(datas.accountId, headers, {
          key: 'accountManager',
          value: datas.managerAccount,
        })
      );
    }
    if (datas.daily_quota) {
      promises.push(
        this.postAdditionalInformation(datas.accountId, headers, {
          key: 'nirror_available_quota',
          value: datas.daily_quota,
        })
      );
    }
    return axios.all([promises]);
  },
  postUsers(accountId, users, headers) {
    const usersPromises = users.map(user =>
      this.callAPI(
        `${this.apiUrlTemplate(this.routes.membership, {
          account_id: accountId,
        })}`,
        headers,
        'POST',
        {
          email: user.email,
          role: user.right,
        }
      )
    );
    return axios.all(usersPromises);
  },
  putRights(url, headers, data) {
    return axios({
      method: 'PUT',
      url: `${API_APP2 + url}`,
      headers,
      data,
    });
  },
  getTranslations(locale = 'en') {
    return axios.get(`${API_APP2}${this.routes.translations}`, { params: { _locale: locale } });
  },
  getManagerAccount(headers) {
    return axios({
      method: 'get',
      url: `${API_APP2 + this.routes.managerAccount}`,
      params: { _max_per_page: 0 },
      headers,
    });
  },
  getSalesForceAccount(headers, name = '') {
    return axios({
      method: 'get',
      url: `${API_APP2 + this.routes.salesforce}`,
      headers,
      params: {
        'filter[name]': name,
      },
    });
  },
  getInitDatas(headers) {
    return axios.all([this.getTranslations(), this.getManagerAccount(headers), this.getSalesForceAccount(headers)]);
  },
  refreshToken(refreshToken) {
    const data = {
      client_id: this.CLIENTID,
      client_secret: this.CLIENTSECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    };
    return this.callAPI(this.routes.auth, {}, 'POST', data);
  },
  handleTokenRefreshing(errorResponseAccounts, tokens, callback) {
    if (errorResponseAccounts.status === 401) {
      this.refreshToken(tokens.APP2RT)
        .then(({ data }) => {
          tokens.set('APP2WT', data.access_token);
          tokens.set('APP2RT', data.refresh_token);
          if (callback) {
            callback();
          }
        })
        .catch(({ response: errorResponseToken }) => {
          if (errorResponseToken.status === 400) {
            window.top.location.href = `${APP_URL}/logout`;
          }
        });
    }
  },
};

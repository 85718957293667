import React from 'react';

const Loader = () => (
  <div className={'ac__loader'}>
    <div />
    <div />
  </div>
);

export default Loader;

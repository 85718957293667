import React from 'react';
import { Input, Select } from '@abtasty/turfu-common-ui';
import staticAccountData from '../../datas/static_account_data';
import Translations from '../../services/Translations';

const AccountPremiumOpt = props => (
  <div className="ac__form__accountOptions__item">
    <React.Fragment>
      <Input
        id={`ac__form__${props.label}`}
        type="checkbox"
        disabled={props.label === 'by_api'}
        className="ac__form__checkbox"
        onChange={props.handlePremiumOptionChange}
        value={props.label}
        checked={props.option}
      />
      <label htmlFor={`ac__form__${props.label}`} className={props.label === 'by_api' ? 'tooltip' : null}>
        {Translations.getTranslation(`ac-option-${props.label}`)}
        {props.label === 'by_api' && (
          <span className="tooltiptext">{Translations.getTranslation('activation_coming_soon')}</span>
        )}
      </label>
    </React.Fragment>
    {props.option &&
      props.label === 'insights_session_recording' && (
        <div>
          <label htmlFor="ac-daily_quota">{Translations.getTranslation('ac-daily_quota')}</label>
          <Select
            id="ac-daily_quota"
            className="ac-daily_quota ac__form__select"
            onChange={props.handlePremiumOptionSRDailyQuota}
            value={props.dailyQuota}
            clearable={false}
            options={staticAccountData.daily_quota}
          />
        </div>
      )}
  </div>
);

export default AccountPremiumOpt;

import React, { Component } from 'react';
import { Button } from '@abtasty/turfu-common-ui';
import AccountPremiumOpts from '../AccountPremiumOpts';
import Translations from '../../services/Translations';
import AccountManager from '../AccountManager/AccountManager';

class FormStepTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    };
  }

  handleSubmitForm = e => {
    e.preventDefault();
    if (this.checkValidForm()) {
      return this.props.handleSubmit('Two');
    }
    return this.displayError();
  };

  checkValidForm = () => {
    if (this.props.premiumOptions.insights_session_recording && !this.props.dailyQuota) return false;
    return true;
  };

  displayError = () => {
    this.errorTimer = setTimeout(() => {
      this.setState({ error: '' });
    }, 4000);

    this.setState({ error: 'Please fill the form properly' }, () => this.errorTimer);
  };

  componentWillUnmount() {
    clearTimeout(this.errorTimer);
  }

  render() {
    return (
      <form onSubmit={e => this.handleSubmitForm(e)}>
        <AccountManager
          managerAccountDatas={this.props.managerAccountDatas}
          managerAccount={this.props.managerAccount}
          handleManagerAccount={this.props.handleManagerAccount}
        />
        <AccountPremiumOpts
          premiumsOptionsAvailable={this.props.premiumOptions}
          handlePremiumOptionChange={this.props.handlePremiumOptionChange}
          handlePremiumOptionSRDailyQuota={this.props.handlePremiumOptionSRDailyQuota}
          dailyQuota={this.props.dailyQuota}
        />
        <Button className="ac__form__submit-btn" size="medium" label={Translations.getTranslation('ac-save')} />
        <p className="ac__form__error">{this.state.error ? this.state.error : null}</p>
      </form>
    );
  }
}

export default FormStepTwo;

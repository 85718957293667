import React, { Component } from 'react';
import moment from 'moment';
import FormStepOne from '../FormStepOne';
import FormStepTwo from '../FormStepTwo';
import FormStepThree from '../FormStepThree';

export default class AccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPack: '',
      uvPack: '',
      expDatePack: '',
      accountName: '',
      managerAccount: '',
      salesforceAccount: null,
      dailyQuota: 0,
      premiumOptions: {
        by_api: false,
        predictive: false,
        plugins: false,
        menu_lab: false,
        smart_report_filters: false,
        report_export_data: false,
        dmp: false,
        planning: false,
        insights_overview: false,
        insights_queryform: false,
        insights_session_recording: false,
        insights_nps: false,
      },
      users: [],
    };
  }

  handleSubmit = step => this.props[`handleSubmit${step}`](this.state);

  handleSalesforceAccountChange = salesforceAccount => this.setState({ salesforceAccount });

  handlePackChange = target => {
    this.setState({
      selectedPack: target ? target.value : '',
      expDatePack:
        target.value === 'quota_custom'
          ? moment()
              .add(1, 'y')
              .format('YYYY-MM-DD')
          : '',
    });
  };

  handleUVPack = e => this.setState({ uvPack: e.target.value });

  handleExpDatePack = expDatePack => {
    this.setState({ expDatePack: moment(expDatePack).format('YYYY-MM-DD') });
  };

  handleAccountName = e => this.setState({ accountName: e.target.value });

  handleManagerAccount = target => this.setState({ managerAccount: target ? target.value : '' });

  handleAddUser = newUser => {
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regexEmail.test(newUser.email) && !newUser.right) return 'Fill the form properly';
    else if (!regexEmail.test(newUser.email)) return 'Enter a valid email address';
    else if (!newUser.right) return 'Select a role';
    else if (newUser.email === window.localStorage.getItem('userEmail'))
      return 'This user is already linked to this account';

    return this.setState(prevState => ({ users: [...prevState.users, newUser] }));
  };

  handleDeleteUser = idx => {
    this.state.users.splice(idx, 1);
    this.setState({ users: this.state.users });
  };

  handlePremiumOptionChange = e => {
    const statePremiumOpts = {
      ...this.state.premiumOptions,
      [e.target.value]: e.target.checked,
    };
    const dailyQuota = e.target.value === 'insights_session-recording' && !e.target.checked ? 0 : this.state.dailyQuota;

    this.setState({
      dailyQuota,
      premiumOptions: statePremiumOpts,
    });
  };

  handlePremiumOptionSRDailyQuota = ({ value }) => {
    this.setState({ dailyQuota: value });
  };

  handleStepForm = () => {
    if (this.props.step === 1)
      return (
        <FormStepOne
          handleSalesforceAccountInputChange={this.props.handleUpdateSalesforceData}
          accountName={this.state.accountName}
          expDatePack={this.state.expDatePack}
          salesforceAccount={this.state.salesforceAccount}
          handleAccountName={this.handleAccountName}
          handleExpDatePack={this.handleExpDatePack}
          handlePackChange={this.handlePackChange}
          handleSalesforceAccountChange={this.handleSalesforceAccountChange}
          handleSubmit={this.handleSubmit}
          handleUVPack={this.handleUVPack}
          salesforceDatas={this.props.salesforceDatas}
          selectedPack={this.state.selectedPack}
          uvPack={this.state.uvPack}
        />
      );
    if (this.props.step === 2)
      return (
        <FormStepTwo
          handlePremiumOptionChange={this.handlePremiumOptionChange}
          handlePremiumOptionSRDailyQuota={this.handlePremiumOptionSRDailyQuota}
          handleSubmit={this.handleSubmit}
          managerAccount={this.state.managerAccount}
          managerAccountDatas={this.props.managerAccountDatas}
          premiumOptions={this.state.premiumOptions}
          dailyQuota={this.state.dailyQuota}
          premiumsOptionsAvailable={this.state.premiumsOptionsAvailable}
          salesforceDatas={this.props.salesforceDatas}
          handleManagerAccount={this.handleManagerAccount}
        />
      );
    return (
      <FormStepThree
        handleAddUser={this.handleAddUser}
        handleDeleteUser={this.handleDeleteUser}
        handleSubmit={this.handleSubmit}
        users={this.state.users}
      />
    );
  };

  render() {
    return this.handleStepForm();
  }
}

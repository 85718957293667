import React from 'react';
import { Input } from '@abtasty/turfu-common-ui';
import Translations from '../../services/Translations';

const AccountName = props => (
  <div className="ac__form__group">
    <label htmlFor="ac__form__accountName">2 - {Translations.getTranslation('ac-accountName')}</label>
    <Input
      id="ac__form__accountName"
      className="ac__form__input"
      type="text"
      onChange={props.handleAccountName}
      value={props.accountName}
    />
  </div>
);

export default AccountName;

import React, { Component } from 'react';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { Select, Input } from '@abtasty/turfu-common-ui';
import staticAccountData from '../../datas/static_account_data';
import Translations from '../../services/Translations';

export default class AccountPack extends Component {
  render() {
    return (
      <div className="ac__form__group">
        <label htmlFor="ac__form__pack-name">{Translations.getTranslation('ac-accountPack-name')}</label>
        <Select
          className="ac__form__pack-name ac__form__select"
          onChange={this.props.handlePackChange}
          value={this.props.selectedPack}
          options={staticAccountData.packs}
        />
        {this.props.selectedPack &&
          this.props.selectedPack === 'quota_custom' && (
            <div>
              <label htmlFor="ac__form__pack-uv">{Translations.getTranslation('ac-accountPack-uv')}</label>
              <Input
                id="ac__form__pack-uv"
                type="number"
                className="ac__form__pack-uv ac__form__input"
                onChange={this.props.handleUVPack}
                value={this.props.uvPack}
              />
              <label htmlFor="ac__form__pack-exp-date">{Translations.getTranslation('ac-accountPack-expDate')}</label>
              <DayPickerInput
                id="ac__form__pack-exp-date"
                className="ac__form__pack-exp-date"
                formatDate={formatDate}
                parseDate={parseDate}
                onDayChange={this.props.handleExpDatePack}
                placeholder="YYYY-MM-DD"
                value={this.props.expDatePack}
                format="YYYY-MM-DD"
              />
            </div>
          )}
      </div>
    );
  }
}
